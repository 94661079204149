import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#4B6EF5',  // Update to the exact primary color
    },
    background: {
      default: '#0F172A',  // Update to the exact background color
      paper: '#0F172A',
    },
    text: {
      primary: '#FFFFFF',  // Set text color to white
      secondary: '#64748B',  // Set secondary text color
    },
  },
  typography: {
    fontFamily: 'Inter, Arial, sans-serif',  // Update to the exact font
    h1: {
      fontSize: '2.25rem',  // Adjust heading sizes as needed
      fontWeight: 700,
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    button: {
      textTransform: 'none',
    },
  },
});

export default theme;
