import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../axiosInstance";
import axios from "axios";

// Fetch purchased courses by userId
export const fetchPurchasedCourses = createAsyncThunk(
  'courses/fetchPurchasedCourses',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`https://us-central1-projectd-b48c9.cloudfunctions.net/api/my-course/getMyCourse/${userId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch course by courseId
export const findCourseById = createAsyncThunk(
  'courses/findCourseById',
  async (courseId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`https://us-central1-projectd-b48c9.cloudfunctions.net/api/courses/getCourseById/${courseId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const courseSlice = createSlice({
  name: 'courses',
  initialState: {
    purchasedCourses: [],
    courseDetails: null,  // Store course details fetched by courseId
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle fetchPurchasedCourses
      .addCase(fetchPurchasedCourses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPurchasedCourses.fulfilled, (state, action) => {
        state.loading = false;
        state.purchasedCourses = action.payload;
      })
      .addCase(fetchPurchasedCourses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle findCourseById
      .addCase(findCourseById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(findCourseById.fulfilled, (state, action) => {
        state.loading = false;
        state.courseDetails = action.payload;
      })
      .addCase(findCourseById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default courseSlice.reducer;
