import React, { useEffect, useState } from 'react';
import { Button,Paper, CircularProgress, DialogContent, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import StyledTextField from '../../util/StyledTextField';
import { submitRegistration,clearRegistrationState } from './registrationSlice';
import theme from '../../theme';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';

const RegistrationForm = ({ selectedWorkshop, handleClose }) => {
  // const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch()
  const { loading, success, error } = useSelector((state) => state.registration);

  useEffect(() => {
    return () => {
      dispatch(clearRegistrationState());
    };
  }, [dispatch]);

  // Validation schema
  const validationSchema = Yup.object({
    fullName: Yup.string().required('Full Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    role: Yup.string().required('Please select your role'),
  });

  // Handle form submission
  const handleSubmit = (values, { setSubmitting }) => {
    const registrationData = {
      fullName: values.fullName,
      email: values.email,
      role: values.role,
      workshopTitle: selectedWorkshop.title,
      workshopDate: selectedWorkshop.date,
    };

    dispatch(submitRegistration(registrationData))
  };

  return (
    <DialogContent sx = {{backgroundColor: theme.palette.background.default}}>
      <Formik
        initialValues={{ fullName: '', email: '', role: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            {/* Reusable StyledTextField */}
            <StyledTextField
              name="fullName"
              label="Full Name"
              error={errors}
              touched={touched}
            />
            <StyledTextField
              name="email"
              label="Email Address"
              error={errors}
              touched={touched}
            />

            {/* Dropdown for role */}
            <FormControl fullWidth margin="normal" error={touched.role && Boolean(errors.role)}>
              <InputLabel>I am a...</InputLabel>
              <Field as={Select} name="role" label="I am a..." fullWidth>
                <MenuItem value="student">Student</MenuItem>
                <MenuItem value="softwareEngineer">Software Engineer</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Field>
              {touched.role && errors.role && (
                <Typography variant="body2" color="error">
                  {errors.role}
                </Typography>
              )}
            </FormControl>
            {error && (
                  <Typography variant="body2" color="error" sx={{  fontFamily: 'Inter, Arial, sans-serif' }}>
                    {error}
                  </Typography>
                )}
                 {success && (
                  <Typography variant="body2" color="success" sx={{  fontFamily: 'Inter, Arial, sans-serif' }}>
                    {error}
                  </Typography>
                )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting || loading}
              sx={{ mt: 3, mb: 2, backgroundColor: '#4B6EF5', fontFamily: 'Inter, Arial, sans-serif', fontWeight: 700 }}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Register'}
            </Button>
          </Form>
        )}
      </Formik>
    </DialogContent>
  );
};

export default RegistrationForm;
