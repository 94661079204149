import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { db } from '../../firebase'; // Import the initialized Firestore instance
import { collection, addDoc, serverTimestamp, query, where } from 'firebase/firestore';

// Thunk to handle registration submission
export const submitRegistration = createAsyncThunk(
  'registration/submit',
  async (registrationData, { rejectWithValue }) => {
    try {
      // Add server timestamp
      const dataWithTimestamp = {
        ...registrationData,
        timestamp: serverTimestamp(),
      };


      const q = query(
        collection(db , 'registrations'),
        where('email', '==',registrationData.email),
        
      )


      const docRef = await addDoc(collection(db, 'registrations'), dataWithTimestamp);
      return { id: docRef.id, ...registrationData, timestamp: new Date() }; // Adjust as needed
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const registrationSlice = createSlice({
  name: 'registration',
  initialState: {
    loading: false,
    success: false,
    error: null,
  },
  reducers: {
    clearRegistrationState: (state) => {
      state.loading = false;
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitRegistration.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(submitRegistration.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
      })
      .addCase(submitRegistration.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      });
  },
});

export const { clearRegistrationState } = registrationSlice.actions;

export default registrationSlice.reducer;
