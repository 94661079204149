
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Card, CardContent,CardMedia, Typography, CardActions, Button, Container, CircularProgress } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchPurchasedCourses } from '../course/courseslice/myCourseSlice'; // Adjust path as necessary

const MyCourses = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { purchasedCourses, loading, error } = useSelector((state) => state.myCourse);
  const userDetails = useSelector((state) => state.auth.userDetails);

  useEffect(() => {
    const userId = userDetails.uid; // Replace with actual user ID
    dispatch(fetchPurchasedCourses(userId));
  }, [dispatch]);

  const handleViewCourse = (courseId) => {
    navigate(`/courses/${courseId}` , {state:{from:location}});
  };

  return (
    <Container sx={{ py: 8 }} maxWidth="md">
      <Typography variant="h4" gutterBottom>
        My Courses
      </Typography>
      {loading ? (
        <Grid container justifyContent="center" alignItems="center" style={{ height: '50vh' }}>
          <CircularProgress />
        </Grid>
      ) : error ? (
        <Typography color="error">Something went wrong pleas try again</Typography>
      ) : (
        <Grid container spacing={4}>
          {purchasedCourses.map((course) => (
            <Grid item key={course.id} xs={12} sm={6} md={4}>
              <Card sx={{ boxShadow: 5 }}> {/* Add shadow effect */}
              <CardMedia
                  component="img"
                  height="140"
                  image={course.thumbnail}
                  alt={course.title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div" sx={{ mt: 2 }}>
                    {course.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" paragraph>
                    {course.description}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" onClick={() => handleViewCourse(course.id)}>
                    View Course
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default MyCourses;

