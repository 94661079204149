import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { auth } from '../../firebase';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import axios from "axios";

const initialState = {
  isAuthenticated: false,
  userDetails: null,
  loading: false,
  error: null
};

export const createUser = createAsyncThunk(
  'user/register',
  async (userDetails, thunkAPI) => {
    try {
      // Create user with Firebase Auth
      const userCredentials = await createUserWithEmailAndPassword(
        auth,
        userDetails.email,
        userDetails.password
      );
      const user = userCredentials.user;

      // Get Firebase Auth token
      const token = await user.getIdToken();
      localStorage.setItem('token', token);

      // Create user in your backend
      const response = await axios.post(
        'https://us-central1-projectd-b48c9.cloudfunctions.net/api/users/create-user',
        {
          uid: user.uid,
          fullName: userDetails.userName,
          email: user.email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Combine Firebase user data and backend response
      const completeUserDetails = {
        uid: user.uid,
        email: user.email,
        fullName: userDetails.userName,
        ...response.data, // Assuming your backend returns additional user data
      };

      return completeUserDetails;
    } catch (error) {
      console.error("User registration failed:", error);

      let errorMessage = 'Registration failed. Please try again.';
      if (error.code === 'auth/email-already-in-use') {
        errorMessage = 'This email is already in use. Please use a different email.';
      } else if (error.code === 'auth/invalid-email') {
        errorMessage = 'Invalid email format. Please check your email.';
      } else if (error.code === 'auth/weak-password') {
        errorMessage = 'Weak password. Please use a stronger password.';
      }

      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const loginUser = createAsyncThunk(
  'users/login',
  async (userDetails, thunkAPI) => {
    try {
      const userCredentials = await signInWithEmailAndPassword(auth, userDetails.email, userDetails.password);
      const token = await userCredentials.user.getIdToken();
      localStorage.setItem('token', token);
      return userCredentials.user;
    } catch (error) {
      console.error("Login failed:", error.code);

      let errorMessage = 'Login failed. Please try again.';
      if (error.code === 'auth/invalid-email') {
        errorMessage = 'Invalid email format. Please check your email.';
      } else if (error.code === 'auth/user-disabled') {
        errorMessage = 'This user account has been disabled.';
      } else if (error.code === 'auth/user-not-found') {
        errorMessage = 'No user found with this email.';
      } else if (error.code === 'auth/invalid-credential') {
        errorMessage = 'Incorrect password. Please try again.';
      }

      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.userDetails = null;
      state.loading = false;
      state.error = null;
      localStorage.removeItem('token'); // Clear token on logout
    },
    clearError: (state) => {
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.userDetails = action.payload;
        state.loading = false;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.userDetails = action.payload;
        state.loading = false;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { logout,clearError } = authSlice.actions;

export default authSlice.reducer;
