import { configureStore } from "@reduxjs/toolkit";
import authReducer from './pages/auth/authSlice'
import paymentReducer from "./pages/payment/PaymentSlice";
import coursesReducer from "./components/course/courseslice/allCoursesSlice";
import myCourseReducer from "./components/course/courseslice/myCourseSlice";
import registrationReducer from "./pages/registration/registrationSlice";


const store = configureStore({
    reducer:{
      auth:authReducer,
      pay:paymentReducer,
      courses: coursesReducer,
      myCourse:myCourseReducer,
      registration:registrationReducer
    }
});

export default store;