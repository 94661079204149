import React, { useState } from 'react';
import { Typography, TextField, Button, Box, Grid, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebase'; // Import your Firebase auth instance

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handlePasswordReset = async () => {
    setLoading(true);
    setError('');
    setMessage('');
    try {
      await sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        // ..
        setMessage('If Your email address is registered with us You will receive a password receive link! Check your inbox.');
      })
    
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };

  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ height: '100vh', backgroundColor: '#0F172A' }}>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5" sx={{ color: '#fff', fontFamily: 'Inter, Arial, sans-serif', fontWeight: 700 }}>
            Reset your password
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputLabelProps={{ style: { color: '#64748B' } }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#1E293B',
                },
                '&:hover fieldset': {
                  borderColor: '#4B6EF5',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#4B6EF5',
                },
              },
              input: { color: '#FFFFFF' },
            }}
          />
          {error && (
            <Typography variant="body2" color="error" sx={{ mt: 2, fontFamily: 'Inter, Arial, sans-serif' }}>
              {error}
            </Typography>
          )}
          {message && (
            <Typography variant="body2" color="success" sx={{ mt: 2, fontFamily: 'Inter, Arial, sans-serif' }}>
              {message}
            </Typography>
          )}
          <Button
            type="button"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, backgroundColor: '#4B6EF5', fontFamily: 'Inter, Arial, sans-serif', fontWeight: 700 }}
            onClick={handlePasswordReset}
            disabled={loading}
          >
            {loading ? 'Sending...' : 'Send Password Reset Email'}
          </Button>
          <Button
            type="button"
            fullWidth
            variant="text"
            sx={{ mt: 3, mb: 2, fontFamily: 'Inter, Arial, sans-serif', fontWeight: 700 }}
            onClick={() => navigate('/login')}
          >
            Back to Sign In
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
