import React from 'react';
import { Typography } from '@mui/material';
import HeroSection from '../../components/herosection/heroSection';
import CourseList from '../../components/course/courseList';
import Footer from '../../components/footer/Footer';

const Homepage = () => {
  return (
    <div>
      <HeroSection/>
      <CourseList/>
      {/* <Footer/> */}
    </div>
  );
};

export default Homepage;
