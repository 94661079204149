import React from 'react';
import { Typography, Button, Container, Box } from '@mui/material';

const HeroSection = () => {
  return (
    // <Box sx={{ bgcolor: 'background.paper', pt: 8, pb: 6 }}>
    //   <Container maxWidth="sm">
    //     <Typography component="h1" variant="h2" align="center" color="text.primary" gutterBottom>
    //       Welcome to DSVCoding
    //     </Typography>
    //     <Typography variant="h5" align="center" color="text.secondary" paragraph>
    //       Learn complete software development with our comprehensive courses.
    //     </Typography>
    //     <Box sx={{ display: 'flex', justifyContent: 'center' }}>
    //       <Button variant="contained" color="primary" sx={{ mr: 2 }}>
    //         Get Started
    //       </Button>
    //       <Button variant="outlined" color="primary">
    //         Learn More
    //       </Button>
    //     </Box>
    //   </Container>
    // </Box>
    <Box sx={{bgcolor: 'background.paper',pt:8,pb:6}}>
        <Container maxWidth = 'sm'>
            <Typography component="h1" variant="h2" align="center" color="text.primary" gutterBottom>Welcome to DSVCoding</Typography>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>Learn complete software development withour comprehensive courses</Typography>
               <Box sx={{ display: 'flex', justifyContent: 'center' }}>
           <Button variant="contained" color="primary" sx={{ mr: 2 }}>
             Get Started
           </Button>
           <Button variant="outlined" color="primary">
         Learn More
           </Button>
         </Box>
        </Container>

    </Box>
  );
};

export default HeroSection;
