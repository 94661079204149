import React from 'react';
import { Typography, Container, Box, Link } from '@mui/material';

const Footer = () => {
  return (
    <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
      <Container maxWidth="lg">
        {/* Footer Title */}
        <Typography variant="h6" align="center" gutterBottom>
          DSVCoding
        </Typography>
        {/* Copyright Notice */}
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          © 2024 DSVCoding. All rights reserved.
        </Typography>
        {/* Contact Us and Policies */}
        <Box sx={{ mt: 4 }}>
          {/* <Typography variant="body1" align="center" color="text.primary">
            Contact Us: <Link href="mailto:contact@dsvcoding.com">8074384691</Link>
          </Typography> */}
                <Typography variant="body1" align="center" color="text.primary">
            Contact Us: <Link >7382542759</Link>
          </Typography>
          <Typography variant="body1" align="center" color="text.primary">
            Mail Us: <Link href="mailto:support@dsvcoding.com">support@dsvcoding.com</Link>
          </Typography>
          <Typography variant="body1" align="center" color="text.primary">
            Address: 357-3/28 Sri Vekateshwara Colony, Gopalpur  TELANGANA 506009
          </Typography>
          <Typography variant="body1" align="center" color="text.primary">
            <Link href="/terms" color="inherit">Terms and Conditions</Link> | <Link href="/privacy" color="inherit">Refund Policies</Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
