import React from 'react';
import { TextField } from '@mui/material';
import { Field } from 'formik';

const StyledTextField = ({ name, label, type = 'text', error, touched, ...props }) => {
  return (
    <Field
      as={TextField}
      fullWidth
      margin="normal"
      required
      name={name}
      label={label}
      type={type}
      error={touched[name] && Boolean(error[name])}
      helperText={touched[name] && error[name]}
      InputLabelProps={{ style: { color: '#64748B' } }}
      sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#1E293B',
          },
          '&:hover fieldset': {
            borderColor: '#4B6EF5',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#4B6EF5',
          },
        },
        input: { color: '#FFFFFF' },
      }}
      {...props}
    />
  );
};

export default StyledTextField;
