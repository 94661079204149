import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography, Button, Container, Box, Card, CardContent, CardMedia } from '@mui/material';

const ViewCourse = ({ course ,isCoursePurchased}) => {
  const userDetails = useSelector((state) => state.auth.userDetails);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();
  const location = useLocation();
  const simplifiedUserDetails = {
    username: userDetails?.username,
    email: userDetails?.email,
    userId:userDetails?.uid
    // Add more properties if needed
  };

  const handleEnroll = () => {
    if (!isAuthenticated) {
      // Navigate to register with the current location state
      console.log(location, "location")
      navigate('/register', { state: { from: location , userDetails:simplifiedUserDetails } });
    } else {
      // Redirect to payment page or initiate payment directly here
      navigate('/payment', { state: { course: course, userDetails: simplifiedUserDetails } });
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 8 }}>
      <Card sx={{boxShadow: 5}}>
        <CardMedia
          component="img"
          height="240"
          image={course.thumbnail}
          alt={course.title}
        />
        <CardContent>
          {/* Course Title */}
          <Typography variant="h4" component="h1" gutterBottom>
            {course.title}
          </Typography>
          {/* Course Description */}
          <Typography variant="body1" color="text.secondary" paragraph>
            {course.description}
          </Typography>
          {/* Price and Discounted Price */}
          {!isCoursePurchased && <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h5" color="text.primary">
              ₹{course.discountedPrice}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ textDecoration: 'line-through' }}>
              ₹{course.price}
            </Typography>
          </Box>}
          {/* Enroll Now Button */}
          {!isCoursePurchased && <Box display="flex" justifyContent="center">
            <Button variant="contained" color="primary" onClick={handleEnroll}>
              Enroll Now
            </Button>
          </Box>}
        </CardContent>
      </Card>
    </Container>
  );
};

export default ViewCourse;
