import React, { useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Card, CardContent, Typography, Box, Button, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { createOrder, validateOrder, resetOrder } from './PaymentSlice'; // Import the resetOrder action

const Payment = () => {
  const scriptRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  const userData = useSelector((state) => state.auth.userDetails)
  console.log(userData , "user data")
  const userDetails = {
    username: userData?.username,
    email: userData?.email,
    userId:userData?.uid
    // Add more properties if needed
  };

  const { course } = location.state || {};
  console.log(location.state,"state value")

  const dispatch = useDispatch();
  const { orderId, loading, error, validationLoading, validationError, isValid } = useSelector((state) => state.pay);
  console.log(course,"course details")
  console.log(userDetails,"user details")
  console.log(validationError , "error")

  useEffect(() => {
    if (orderId) {
      loadRazorpayScript();
    }
  }, [orderId]);

  useEffect(() => {
    if (isValid) {
      console.log("inside valid");
      // Navigate to courses page and reset orderId after successful payment
      dispatch(resetOrder()); // Dispatch an action to reset orderId
      navigate('/mycourses');
    }
  }, [isValid, navigate, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetOrder());
    }
  },[dispatch])
  const loadRazorpayScript = () => {
    if (scriptRef.current) {
      // Script already loaded, proceed to open Razorpay
      openRazorpay();
      return;
    }

    // Add Razorpay script
    const script = document.createElement('script');
    script.src = "https://checkout.razorpay.com/v1/checkout.js"
    script.async = true;
    script.onload = () => {
      scriptRef.current = true; // Mark script as loaded
      openRazorpay();
    };

    document.body.appendChild(script);
  };

  const openRazorpay = () => {
    if (orderId) {
      const options = {
        key: process.env.RZP_KEY_ID, // Enter the Key ID generated from the Dashboard
        amount: (course.discountedPrice * 100).toString(), // Amount in currency subunits
        currency: "INR",
        name: process.env.BUSINESS_NAME, // Your business name
        description: `Payment for ${course.title}`,
        image: "https://example.com/your_logo",
        order_id: orderId,
        handler: function (response) {
          // Verify the payment
          dispatch(validateOrder({
             order_id: response.razorpay_order_id,
            // order_id: "response.razorpay_order_id",
            payment_id: response.razorpay_payment_id,
            signature: response.razorpay_signature,
            userId:userDetails.userId,
            courseId:course.id

          }));
        },
        prefill: { // Prefill customer's information
          name: `${userDetails.userName}`, // Your customer's name
          email: `${userDetails.email}`,
          contact:`${userDetails?.mobile}`
        },
        notes: {
          address: "Razorpay Corporate Office"
        },
        theme: {
          color: "#3399cc"
        }
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.on('payment.failed', function (response) {
        alert(`Error: ${response.error.description}`);
      });

      rzp1.open();
    }
  };

  const handleCheckout = () => {
    dispatch(createOrder({
      amount: course.discountedPrice, // Amount in rupees
      currency: "INR",
      receipt: `receipt_${course.title}`
    }));
  };

  if (!course) {
    return <Typography variant="h6">Course details not available.</Typography>;
  }

  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Card>
        <CardContent>
          {/* Course Title */}
          <Typography variant="h4" component="h1" gutterBottom>
            {course.title}
          </Typography>
          {/* Course Description */}
          <Typography variant="body1" color="text.secondary" paragraph>
            {course.description}
          </Typography>
          {/* Price and Discounted Price */}
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h5" color="text.primary">
              ₹{course.discountedPrice}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ textDecoration: 'line-through' }}>
              ₹{course.price}
            </Typography>
          </Box>
          {/* Error Display */}
          {error && <Typography color="error">Error: {error}</Typography>}
          {validationError && <Typography color="error">{validationError}</Typography>}
          {isValid && <Typography color="success">Payment Verified Successfully!</Typography>}
          {/* Pay Button */}
          <Box display="flex" justifyContent="center">
            <Button variant="contained" color="primary" onClick={handleCheckout} disabled={loading || validationLoading}>
              {loading || validationLoading ? <CircularProgress size={24} /> : 'Check Out'}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Payment;
