import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Grid, Card,Paper, CardContent, Typography, CardActions, Button, Container, Box, CardMedia, Dialog, DialogTitle, DialogActions } from '@mui/material';
import RegistrationForm from '../../pages/registration/RegistrationForm'; // Import the RegistrationForm

// Dummy data for workshops
const workshops = [
  {
    id: 'react_basics_ws_8074',
    title: 'React Basics Workshop',
    description: 'Learn the basics of React in this interactive workshop.',
    date: '2024-09-15 to 2024-09-25',
    time: '10:00 AM - 1:00 PM',
    price: 499,
    image: 'https://via.placeholder.com/300', // Placeholder thumbnail
  }
];

const Workshop = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedWorkshop, setSelectedWorkshop] = useState(null);

  const handleOpen = (workshop) => {
    setSelectedWorkshop(workshop);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedWorkshop(null);
  };

  return (
    <Container sx={{ py: 8 }} maxWidth="md">
      <Typography variant="h1" sx={{ color: '#fff', marginBottom: '1rem' }}>
        Upcoming Workshops
      </Typography>
      <Grid container spacing={4}>
        {workshops.map((workshop) => (
          <Grid item key={workshop.id} xs={12} sm={6} md={4}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', boxShadow: 5 }}>
              <CardMedia
                component="img"
                height="140"
                image={workshop.image}
                alt={`${workshop.title} thumbnail`}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h5" component="div">
                  {workshop.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" paragraph>
                  {workshop.description}
                </Typography>
                <Typography variant="body2"  paragraph>
                  {workshop.date}
                </Typography>
                <Typography variant="body1" color="text.primary">
                    ₹{workshop.price}
                  </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" variant="contained" color="primary" onClick={() => handleOpen(workshop)}>
                  Register
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Dialog for Registration */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" >
        <DialogTitle sx={{backgroundColor: theme.palette.background.default}}>Register for {selectedWorkshop?.title}</DialogTitle>
        <RegistrationForm selectedWorkshop={selectedWorkshop} handleClose={handleClose} />
        <DialogActions sx={{backgroundColor: theme.palette.background.default}}>
          <Button onClick={handleClose}         variant="contained"
              color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Workshop;
