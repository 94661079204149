import axios from 'axios';

// Create an instance of axios
const axiosInstance = axios.create();

// Add a request interceptor to include the JWT token
axiosInstance.interceptors.request.use(
  (config) => {
    // Get the JWT token from local storage
    const token = localStorage.getItem('token');

    // If the token exists, add it to the Authorization header
    if (token) {
      config.headers.Authorization = `eearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Handle the error
    return Promise.reject(error);
  }
);

export default axiosInstance;
