import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, TextField, Button, Box, Grid, Paper, Link, CircularProgress } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { createUser, loginUser,clearError } from './authSlice';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const AuthPage = ({ isLogin }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isLoginPage = isLogin || location.pathname === '/login';
  const dispatch = useDispatch();
  const { loading, error,isAuthenticated } = useSelector((state) => state.auth);
  // console.log(error,"from auth page")
  const redirectTo = location.state?.from || '/';


  useEffect(() => {

    if(isAuthenticated){
      navigate(redirectTo)
    }

  },[redirectTo,isAuthenticated])

  const initialValues = {
    email: '',
    password: '',
    userName: '',
  };
  useEffect(() => {
    return () => {
      dispatch(clearError());
    };
  }, [dispatch]);

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Required'),
    userName: !isLoginPage ? Yup.string().min(6, 'Username must be at least 6 characters').required('Required') : Yup.string(),
  });

  const handleSubmit = (values, { setSubmitting, setTouched, validateForm }) => {
    validateForm().then(errors => {
      if (Object.keys(errors).length) {
        setTouched({
          email: true,
          password: true,
          userName: !isLoginPage // Only mark username as touched if we're on the sign-up page
        });
      } else {
        const redirectTo = location.state?.from || '/';
        console.log(redirectTo,"redirect")
        const credTwo = { email: values.email, password: values.password, userName: values.userName };
        dispatch(!isLoginPage ? createUser(credTwo) : loginUser(credTwo))
          // .then(() => {
           
          //   navigate(redirectTo)
          // });
      }
      setSubmitting(false);
    });
  };

  const handleLinkClick = (path) => {
    navigate(path, { state: location.state });
  };

  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ height: '100vh', backgroundColor: '#0F172A' }}>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5" sx={{ color: '#fff', fontFamily: 'Inter, Arial, sans-serif', fontWeight: 700 }}>
            {isLoginPage ? 'Sign in to your account' : 'Create your account'}
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, errors, touched }) => (
              <Form>
                <Field
                  as={TextField}
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  InputLabelProps={{ style: { color: '#64748B' } }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#1E293B',
                      },
                      '&:hover fieldset': {
                        borderColor: '#4B6EF5',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#4B6EF5',
                      },
                    },
                    input: { color: '#FFFFFF' },
                  }}
                />
                <Field
                  as={TextField}
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  InputLabelProps={{ style: { color: '#64748B' } }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#1E293B',
                      },
                      '&:hover fieldset': {
                        borderColor: '#4B6EF5',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#4B6EF5',
                      },
                    },
                    input: { color: '#FFFFFF' },
                  }}
                />
                {!isLoginPage && (
                  <Field
                    as={TextField}
                    margin="normal"
                    required
                    fullWidth
                    name="userName"
                    label="Username"
                    type="text"
                    id="userName"
                    error={touched.userName && Boolean(errors.userName)}
                    helperText={touched.userName && errors.userName}
                    InputLabelProps={{ style: { color: '#64748B' } }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1E293B',
                        },
                        '&:hover fieldset': {
                          borderColor: '#4B6EF5',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#4B6EF5',
                        },
                      },
                      input: { color: '#FFFFFF' },
                    }}
                  />
                )}
                {isLoginPage && (
                  <Grid container>
                    <Grid item xs>
                      <Link 
                        href="#" 
                        variant="body2" 
                        sx={{ color: '#4B6EF5', fontFamily: 'Inter, Arial, sans-serif' }}
                        onClick={() => navigate('/forgot-password')} // Add this line to navigate to the Forgot Password page
                      >
                        Forgot password?
                      </Link>
                    </Grid>
                  </Grid>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, backgroundColor: '#4B6EF5', fontFamily: 'Inter, Arial, sans-serif', fontWeight: 700 }}
                  disabled={isSubmitting || loading}
                >
                  {loading ? <CircularProgress size={24} color="inherit" /> : isLoginPage ? 'Sign in' : 'Sign up'}
                </Button>
                {error && (
                  <Typography variant="body2" color="error" sx={{  fontFamily: 'Inter, Arial, sans-serif' }}>
                    {error}
                  </Typography>
                )}
                <Grid container justifyContent="center">
                  <Grid item>
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => handleLinkClick(isLoginPage ? "/register" : "/login")}
                      sx={{ color: '#4B6EF5', fontFamily: 'Inter, Arial, sans-serif' }}
                    >
                      {isLoginPage ? "Not a member? Sign Up Now" : 'Already have an account? Sign in'}
                    </Link>
                  </Grid>
                  {/* <Grid item>
                  <Link
                        component="button"
                        variant="body2"
                        onClick={() => navigate('/phone-auth')}
                        sx={{ color: '#4B6EF5', fontFamily: 'Inter, Arial, sans-serif', mt: 2 }}
                         >
                          
                   Sign in with Phone Number
                     </Link>
                      </Grid> */}

                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AuthPage;












