import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { db, storage } from '../../firebase'; // Your Firebase initialization with Storage
import { Typography, TextField, Button, Container, Box, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { collection, doc, setDoc, getDocs, updateDoc, arrayUnion, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Import storage functions

const AdminPage = () => {
  const [courseTitle, setCourseTitle] = useState('');
  const [courseDescription, setCourseDescription] = useState('');
  const [coursePrice, setCoursePrice] = useState('');
  const [courseDiscountedPrice, setCourseDiscountedPrice] = useState('');
  const [courseThumbnail, setCourseThumbnail] = useState(null); // State for storing the selected image
  const [sectionTitle, setSectionTitle] = useState('');
  const [sectionDescription, setSectionDescription] = useState('');
  const [videoTitle, setVideoTitle] = useState('');
  const [vimeoLink, setVimeoLink] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [courses, setCourses] = useState([]);
  const [sections, setSections] = useState([]);
  const isAdmin = useSelector((state) => state.auth.userDetails?.email === 'testtest123@gmail.com'); // Replace with admin email

  const handleImageUpload = async (image) => {
    const storageRef = ref(storage, `courses/${image.name}`);
    await uploadBytes(storageRef, image);
    const imageUrl = await getDownloadURL(storageRef);
    return imageUrl;
  };

  const handleAddCourse = async () => {
    try {
      let imageUrl = '';
      if (courseThumbnail) {
        imageUrl = await handleImageUpload(courseThumbnail); // Upload the image and get the URL
      }
      const uniqueID = `${courseTitle.toLowerCase().replace(/\s+/g, '-')}-${Math.random().toString(36).substr(2, 5)}`;
      const courseRef = doc(collection(db, 'courses'), uniqueID);
      await setDoc(courseRef, {
        title: courseTitle,
        description: courseDescription,
        price: parseFloat(coursePrice),
        discountedPrice: parseFloat(courseDiscountedPrice),
        thumbnail: imageUrl, // Save the image URL
        sections: []
      });
      console.log('Course added successfully');
      setCourseTitle('');
      setCourseDescription('');
      setCoursePrice('');
      setCourseDiscountedPrice('');
      setCourseThumbnail(null);
      fetchCourses();
    } catch (error) {
      console.error('Error adding course:', error);
    }
  };

  const handleAddSection = async () => {
    if (!selectedCourse) {
      console.error('No course selected');
      return;
    }
    try {
      const courseRef = doc(db, 'courses', selectedCourse);
      await updateDoc(courseRef, {
        sections: arrayUnion({
          title: sectionTitle,
          description: sectionDescription,
          videos: []
        })
      });
      console.log('Section added successfully');
      setSectionTitle('');
      setSectionDescription('');
      fetchSections(selectedCourse);
    } catch (error) {
      console.error('Error adding section:', error);
    }
  };

  const handleAddVideo = async () => {
    if (!selectedCourse || !selectedSection) {
      console.error('No course or section selected');
      return;
    }
    try {
      const courseRef = doc(db, 'courses', selectedCourse);
      const courseDoc = await getDoc(courseRef);
      const sections = courseDoc.data().sections;
      const updatedSections = sections.map(section => {
        if (section.title === selectedSection) {
          return {
            ...section,
            videos: [...section.videos, {
              title: videoTitle,
              vimeoId: vimeoLink.split('/')[4].split('?')[0] // Extracts the ID from the full URL
            }]
          };
        }
        return section;
      });
      await updateDoc(courseRef, { sections: updatedSections });
      console.log('Video added successfully');
      setVideoTitle('');
      setVimeoLink('');
    } catch (error) {
      console.error('Error adding video:', error);
    }
  };

  const fetchCourses = async () => {
    try {
      const coursesSnapshot = await getDocs(collection(db, 'courses'));
      const coursesList = coursesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCourses(coursesList);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  const fetchSections = async (courseId) => {
    try {
      const courseRef = doc(db, 'courses', courseId);
      const courseDoc = await getDoc(courseRef);
      setSections(courseDoc.data().sections);
    } catch (error) {
      console.error('Error fetching sections:', error);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  useEffect(() => {
    if (selectedCourse) {
      fetchSections(selectedCourse);
    }
  }, [selectedCourse]);

  if (!isAdmin) {
    return <Typography variant="h6">Access Denied</Typography>;
  }

  return (
    <Container maxWidth="md" sx={{ mt: 8 }}>
      <Box>
        <Typography variant="h4" component="h1" gutterBottom>
          Admin Panel
        </Typography>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6">Add Course</Typography>
          <TextField
            label="Course Title"
            fullWidth
            margin="normal"
            value={courseTitle}
            onChange={(e) => setCourseTitle(e.target.value)}
          />
          <TextField
            label="Course Description"
            fullWidth
            margin="normal"
            value={courseDescription}
            onChange={(e) => setCourseDescription(e.target.value)}
          />
          <TextField
            label="Course Price"
            fullWidth
            margin="normal"
            value={coursePrice}
            onChange={(e) => setCoursePrice(e.target.value)}
            type="number"
          />
          <TextField
            label="Discounted Price"
            fullWidth
            margin="normal"
            value={courseDiscountedPrice}
            onChange={(e) => setCourseDiscountedPrice(e.target.value)}
            type="number"
          />
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setCourseThumbnail(e.target.files[0])}
          />
          <Button variant="contained" color="primary" onClick={handleAddCourse}>
            Add Course
          </Button>
        </Box>
        {/* Add Section and Video sections remain unchanged */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6">Add Section to Course</Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel>Select Course</InputLabel>
            <Select
              value={selectedCourse}
              onChange={(e) => setSelectedCourse(e.target.value)}
            >
              {courses.map((course) => (
                <MenuItem key={course.id} value={course.id}>
                  {course.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Section Title"
            fullWidth
            margin="normal"
            value={sectionTitle}
            onChange={(e) => setSectionTitle(e.target.value)}
          />
          <TextField
            label="Section Description"
            fullWidth
            margin="normal"
            value={sectionDescription}
            onChange={(e) => setSectionDescription(e.target.value)}
          />
          <Button variant="contained" color="primary" onClick={handleAddSection}>
            Add Section
          </Button>
        </Box>
        <Box>
          <Typography variant="h6">Add Video to Section</Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel>Select Course</InputLabel>
            <Select
              value={selectedCourse}
              onChange={(e) => setSelectedCourse(e.target.value)}
            >
              {courses.map((course) => (
                <MenuItem key={course.id} value={course.id}>
                  {course.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Select Section</InputLabel>
            <Select
              value={selectedSection}
              onChange={(e) => setSelectedSection(e.target.value)}
            >
              {sections.map((section) => (
                <MenuItem key={section.title} value={section.title}>
                  {section.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Video Title"
            fullWidth
            margin="normal"
            value={videoTitle}
            onChange={(e) => setVideoTitle(e.target.value)}
          />
          <TextField
            label="Vimeo Link"
            fullWidth
            margin="normal"
            value={vimeoLink}
            onChange={(e) => setVimeoLink(e.target.value)}
          />
          <Button variant="contained" color="primary" onClick={handleAddVideo}>
            Add Video
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default AdminPage;
