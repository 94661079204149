import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const EnrollPage = () => {
  const navigate = useNavigate();

  const handleEnroll = () => {
    // Handle enrollment logic here
    alert('Enrolled successfully!');
    navigate('/my-courses'); // Redirect to My Courses page after enrollment
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
       You have successfully enrolled in our course
      </Typography>
    </Container>
  );
};

export default EnrollPage;
